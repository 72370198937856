import * as React from 'react'

import { stringifyUrl } from 'query-string'

import NavBar from '../components/nav_bar'
import SeoHeaders from '../components/seo_headers'

const ReservePage = () => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = stringifyUrl({
      url: '//www.opentable.com/widget/reservation/loader',
      query: {
        rid: ['1334317', '1335565'],
        type: 'multi',
        theme: 'tall',
        color: '5',
        dark: 'false',
        iframe: 'true',
        domain: 'com',
        lang: 'es-MX',
        newtab: 'false',
        ot_source: 'Restaurant website',
        cfe: 'true',
      },
    })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <React.Fragment>
      <SeoHeaders
        title='Reservar una mesa'
        description='Reserve una mesa en nuestros restuarantes de forma rápida y sencilla.'
      />
      <NavBar />
    </React.Fragment>
  )
}

export default ReservePage
